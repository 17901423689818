import React from 'react'
import {createPortal} from "react-dom";
import Layout from "../Layout/Layout"
import "./FullScreenAlert.scss"
import classNames from "classnames";

export default function FullScreenAlert({children, className, allowAdminScreen, ...props}) {
  return createPortal(
    <div className={classNames('FullScreenAlert', className)} {...props}>
      <div className='content'>
        <Layout showHeader showFooter={true} hideButtons={true} allowAdminScreen>
          {children}
        </Layout>
      </div>
    </div>
  , document.body);
}
