import React, { useEffect, useState } from 'react'
import "./OutOfService.scss"
import CheckBtmStatusService from '../../services/CheckBtmStatusService';
import { useTranslation } from 'react-i18next';
import FullScreenAlert from '../../components/FullScreenAlert/FullScreenAlert';
import loadingError from '../../assets/images/info-red-animated.gif'
import {btmLog} from "../../services/localBackendService";

export default function OutOfService() {

  const { t } = useTranslation();

  const [outOfServiceStatus, setOutOfServiceStatus] = useState({});

  useEffect(()=>{
    const checkBtmStatusService = new CheckBtmStatusService();

    // fetchStatus fires an event onStatusChange when the status is ready
    checkBtmStatusService.fetchStatus()
        .then(()=>{
          setOutOfServiceStatus( checkBtmStatusService.getOutOfService() );
        })
      .catch((error)=>{
        btmLog('Error checking BTM status. ' + error);
        console.error(error);
    });

    // handle status changes
    const statusListener = ()=>{
      setOutOfServiceStatus( checkBtmStatusService.getOutOfService() );
    }

    checkBtmStatusService.onStatusChange(statusListener);
    return ()=>checkBtmStatusService.removeListener(statusListener);
  },[]);


  return (
    !!outOfServiceStatus?.outOfService &&
    <FullScreenAlert>
      <div className='OutOfService'>
        <div className='content'>
          <div className='d-flex flex-column justify-content-center'>
            <div className='d-flex justify-content-center'>
              <img src={loadingError} alt="Error icon"  className='loader-error'/>
            </div>
            <div className='title'>
              {t('Out of service')}
            </div>
            <div className='description'>
              {outOfServiceStatus?.code || 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </FullScreenAlert>
  )
}
