//Let's order the translations alphabetically
const translations = {
    "Accept": "Accept",
    "An error occurred while printing ticket": "An error occurred while printing ticket",
    "An error occurred while processing purchase": "An error occurred while processing your purchase",
    "Attention!": "Attention!",
    "Back": "Back",
    "Banknotes": "Banknotes",
    "Banknotes inserted in session": "Banknotes inserted in session",
    "Before starting": "Before starting",
    "Before starting, you need to know the terms of use of our BTMs and how we protect your privacy": "Before starting, you need to know the terms of use of our BTMs and how we protect your privacy.",
    "Bills Accepted: ": "Bills Accepted: ",
    "BTM Register": "BTM Register",
    "BTM registered successfully": "BTM registered successfully",
    "BTM linked": "BTM linked",
    "Buy": "Buy",
    "Buy Bitcoin": "Buy Bitcoin",
    "Buy terms": "Buy terms",
    "Check the scanned QR. If the problem persists, contact support.": "Check the scanned QR. If the problem persists, contact support.",
    "Choose the amount in pesos you want to sell, then its equivalent in BTC will be shown": "Choose the amount in pesos you want to sell, then its equivalent in BTC will be shown.",
    "Choose your operation": "Choose your operation",
    "Code not sent": "Code not sent",
    "Close": "Close",
    "Continue": "Continue",
    "Current limit is: ": "Current limit is: {{amount}}",
    "Current transaction limit is: ": "Current transaction limit is: {{amount}}",
    "day": "day",
    "Download the Rayo app": "Download the Rayo® app, to increase your limits.",
    "English": "English",
    "Enjoy your cash and come back soon!": "Enjoy your cash and come back soon!",
    "Enter server url": "Enter server url",
    "Enter your phone number": "Enter your phone number",
    "Error linking BTM": "Error linking BTM",
    "Error getting kyc data": "Error getting user data",
    "Error getting server url": "Error getting server url.",
    "Failed to create transaction": "Failed to create transaction",
    "Failed to send ticket via sms": "Failed to send ticket via sms",
    "Failed to send verification code": "Failed to send verification code",
    "Finish": "Finish",
    "Finishing session with reason": "Finishing session with reason: ",
    "I accept Cryptobox terms and conditions": "I accept Cryptobox terms and conditions",
    "I will perform this operation on my own account and for my own benefit.": "I will perform this operation on my own account and for my own benefit.",
    "I accept privacy policy": "I accept privacy policy",
    "If problem persist contact us": "If problem persist contact us",
    "Identity not verified": "Identity not verified",
    "In case you do not confirm that the operation is for your benefit, you will need to register in our application.": "In case you do not confirm that the operation is for your benefit, you will need to register in our application.",
    "In your application, select the option to receive": "In your application, select the option to receive.",
    "In your ticket you will find the instructions to continue": "In your ticket you will find the instructions to continue.",
    "Insert another bill": "Insert another bill",
    "Incorrect credentials": "Incorrect credentials",
    "Incomplete payment": "Incomplete payment",
    "Increase your limit!": "Increase your limit!",
    "Insert cash": "Insert cash",
    "Insert the bills one by one": "Insert the bills one by one",
    "Instructions": "Instructions",
    "Invalid address": "Invalid address. try again",
    "Invalid code": "Invalid code.",
    "Invalid phone number to make a transaction": "Invalid phone number to make a transaction",
    "Invalid QR code": "Invalid QR code",
    "leave any behind. We appreciate your trust and preference": "leave any behind. We appreciate your trust and preference.",
    "Limits for non-registered users": "<bold>Limits for non-registered users:</bold> Up to $2,000 per day and $20,000 per month.",
    "Limits for registered users": "<bold>Limits for registered users:</bold> Up to $12,000 per transaction, $30,000 daily and $200,000 monthly.",
    "Loading": "Loading",
    "Loading exchange rates, wait a moment": "Loading exchange rates, wait a moment",
    "Make sure the QR code is fully visible on the screen": "Make sure the QR code is fully visible on the screen.",
    "Maximum amount of transactions per day is $30,000 MXN": "Maximum amount of transactions per {{period}} is {{amount}} MXN",
    "Maximum amount per transaction of $12,000.00 MXN": "Maximum amount per transaction of {{amount}} MXN",
    "Multiple deposits": "Multiple deposits",
    "month": "month",
    "Once the required quantity has been sent, you can": "Once the required quantity has been sent, you can",
    "Only mxn banknotes": "Only MXN banknotes - minimum 500 MXN",
    "Open your wallet and select the option to send Bitcoin": "Open your wallet and select the option to send Bitcoin.",
    "Out of service": "Out of service",
    "Pending transaction": "Redeemed ticket",
    "Phone number must have": "Phone number must have at least 8 digits",
    "Please remove your bills from the dispenser and make sure you do not": "Please remove your bills from the dispenser and make sure you do not",
    "Please verify the operation and contact support if there is an error.": "Please verify the operation and contact support if there is an error.",
    "Printing ticket": "Printing ticket",
    "Privacy policy": "Privacy policy",
    "Purchase": "Purchase",
    "QR Code doesn't exists": "QR Code doesn't exists",
    "Reading register key": "Reading register key...",
    "ReceiptMessage": "Hello! You can check your cryptobox transaction at the following link: %s",
    "Redeem Ticket": "Redeem Ticket",
    "Redeemed ticket": "Redeemed ticket",
    "Remember that the first bill you insert must be a 500 MXN bill to continue with the transaction.": "Remember that the first bill you insert must be a 500 MXN bill to continue with the transaction.",
    "Save": "Save",
    "Scan register key": "Scan register key",
    "Scan the QR code of your ticket": "Scan the QR code of your ticket.",
    "Scan the QR code to download the app on your device's store": "Scan the QR code to download the app on your device's store.",
    "Scan your ticket": "Scan your ticket",
    "Scan your wallet": "Scan your wallet",
    "Secure transactions": "Secure transactions",
    "See privacy policy": "See privacy policy",
    "See terms and conditions": "See terms and conditions",
    "Sell": "Sell",
    "Sell Bitcoin": "Sell Bitcoin",
    "Sell_Bitcoin": "Sell Bitcoin",
    "Selling": "Sell",
    "Send": "Send",
    "Send your bitcoin": "Send your bitcoin",
    "Send_": "Send",
    "Sent": "Sent",
    "Server": "Server",
    "Server saved successfully": "Server saved successfully",
    "Session type not found": "sessionType not found ({{sessionType}}). Back to home",
    "Spanish": "Spanish",
    "Starting session": "Starting session",
    "Successful transaction": "Successful transaction.",
    "take 5 to 30 minutes to fully reflect": "take 5 to 30 minutes to fully reflect.",
    "Take your receipt": "Take your receipt",
    "Terms and conditions": "Terms and conditions",
    "Thanks for your preference!": "Thanks for your preference!",
    "The BTM has been successfully linked. Rebooting in 5 seconds..." : "The BTM has been successfully linked. Rebooting in 5 seconds...",
    "The combination of username and password is not valid. Please verify your information and try again.": "The combination of username and password is not valid. Please verify your information and try again.",
    "The first bill entered must be a denomination of $200.00 or $500.00 MXN": "The first bill entered must be a denomination of $200.00 or $500.00 MXN",
    "The first bill entered must be $500": "The first bill entered must be $500",
    "The minimum purchase is $500.00 MXN": "The minimum purchase is $500.00 MXN",
    "The QR code has not been paid in full. If the problem persists, contact support.": "The QR code has not been paid in full. If the problem persists, contact support.",
    "The registration QR code is invalid or has expired. Verify the QR or generate a new one.": "The registration QR code is invalid or has expired. Verify the QR or generate a new one.",
    "The transaction may take up to 60 minutes to be reflected, depending on network saturation": "The transaction may take up to 60 minutes to be reflected, depending on network saturation",
    "This BTM accepts bills of any denomination": "This ATM accepts bills of any denomination.",
    "This BTM does not accept coins": "This BTM does not accept coins",
    "This BTM does not accept torn, stapled, bent or mistreated bills": "This BTM does not accept torn, stapled, bent or mistreated bills",
    "This BTM does not give change": "This BTM does not give change",
    "This BTM only accepts Mexican pesos": "This ATM only accepts Mexican pesos",
    "There was a problem trying to process the transaction. Please try again.":"There was a problem trying to process the transaction. Please try again.",
    "Ticket already charged": "Ticket already charged.",
    "Transaction error": "Transaction error",
    "to withdraw": "to withdraw",
    "Total": "Total",
    "Total charged": "Total charged",
    "Total inserted": "Total inserted",
    "Transaction has not been paid": "Transaction has not been paid",
    "Transaction limit reached": "Transaction limit reached",
    "Try scanning the ticket later. If the problem persists, contact support.": "Try scanning the ticket later. If the problem persists, contact support.",
    "Try scanning ticket later": "Try scanning ticket later",
    "Understood": "Understood",
    "Unknown server error": "Unknown server error",
    "Use the reader at the bottom left of the BTM to scan the QR code of your Wallet": "Use the reader at the bottom left of the BTM to scan the QR code of your Wallet.",
    "Use your wallet to complete the transaction": "Use your wallet to complete the transaction",
    "Verification code": "Code sent to ",
    "Verification code not valid": "Verification code not valid",
    "Verification is only once and is valid for all Cryptobox BTMs in the network": "Verification is only once and is valid for all Cryptobox BTMs in the network.",
    "Verification requirements: INE, RFC with homoclave and verify economic activity": "<bold>Verification requirements:</bold> INE, RFC with homoclave and verify economic activity.",
    "Verified identity": "Verified identity",
    "Verified phone number continuing the transaction": "Verified phone number continuing the transaction",
    "Verification required": "Verification required",
    "Verify": "Verify",
    "Wait for the transaction to be confirmed": "Wait for the transaction to be confirmed.",
    "We are sorry, but you've reached your transaction limit for today": "We're sorry, but you've reached your transaction limit for today. As a registered user, you account with a limit of $30,000 per day and $200,000 per month for transactions.",
    "We are sorry, we do not have enough funds to dispense the transaction. Please try again later.": "We are sorry, we do not have enough funds to dispense the transaction. Please try again later.",
    "We cannot send the verification code at this time. Please try again later.": "We cannot send the verification code at this time. Please try again later.",
    "We have sent an SMS with the transaction ticket to the number you have provided us": "We have sent an SMS with the transaction ticket to the number you have provided us.",
    "We look forward to seeing you back soon to continue operating with us": "We look forward to seeing you back soon to continue operating with us.",
    "We send a verification code": "We have sent a verification code via SMS to the provided number. Enter the verification code to continue.",
    "We have detected a smaller deposit related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount. WhatsApp: 55 5498 8382.": "We have detected a smaller deposit related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount. WhatsApp: 55 5498 8382.",
    "We have detected a larger deposit related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount. WhatsApp: 55 5498 8382.": "We have detected a larger deposit related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount. WhatsApp: 55 5498 8382.",
    "We have detected multiple deposits related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount. WhatsApp: 55 5498 8382.": "We have detected multiple deposits related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount. WhatsApp: 55 5498 8382.",
    "Wrong amount": "Wrong amount",
    "Withdraw your cash": "Withdraw your cash",
    "You have 10 to 15 minutes to send the transaction": "You have 10 to 15 minutes to send the transaction.",
    "You must enter an amount": "You must enter an amount",
    "You will receive your money in cash": "Done! You will receive your money in cash.",
    "Your safety and comfort are our priority! Enjoy reliable and unlimited operations": "Your safety and comfort are our priority! Enjoy reliable and unlimited operations.",
    "Your ticket": "Your ticket",
    "Your transaction": "Your transaction",
    "Your transaction is in progress!": "Your transaction is in progress!",
    "Your verification code is": "Your number is: {{code}}",
    "Your wallet": "Your wallet: "
}

export default translations;
